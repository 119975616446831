import { gql } from "@apollo/client";
import { PartialOrgMagicSettings } from "@hex/common";
import { useCallback } from "react";

import { ORG_ID } from "../orgs";

import { useOrgForMagicSettings } from "./me/useOrgForMagicSettings";
import { useSetOrgMagicSettingsMutation } from "./useUpdateOrgMagicSettings.generated";

gql`
  mutation setOrgMagicSettings($settings: OrgMagicSettings!, $orgId: OrgId!) {
    updateOrgMagicSettings(settings: $settings, orgId: $orgId) {
      id
      magicSettings
    }
  }
`;

export const useUpdateOrgMagicSettings = (): {
  updateOrgMagicTosAcceptedJuly2024: (enabled: boolean) => Promise<void>;
  updateOrgMagicTypeaheadEnabled: (enabled: boolean) => Promise<void>;
} => {
  const { magicSettings, orgId: id } = useOrgForMagicSettings(ORG_ID);
  const [setOrgMagicSettings] = useSetOrgMagicSettingsMutation();

  const updateOrgMagicSettings = useCallback(
    async (newSettings: PartialOrgMagicSettings) => {
      if (id == null) return;
      await setOrgMagicSettings({
        variables: { settings: newSettings, orgId: id },
        optimisticResponse: {
          __typename: "Mutation",
          updateOrgMagicSettings: {
            id,
            __typename: "Org",
            magicSettings: newSettings,
          },
        },
      });
    },
    [id, setOrgMagicSettings],
  );

  const updateSetting = useCallback(
    async <TValue>(
      settingKey: keyof PartialOrgMagicSettings,
      value: TValue,
    ) => {
      await updateOrgMagicSettings({
        ...magicSettings,
        [settingKey]: value,
      });
    },
    [magicSettings, updateOrgMagicSettings],
  );

  return {
    updateOrgMagicTosAcceptedJuly2024: useCallback(
      (enabled: boolean) => updateSetting("acceptedMagicTosJuly2024", enabled),
      [updateSetting],
    ),
    updateOrgMagicTypeaheadEnabled: useCallback(
      (enabled: boolean) => updateSetting("magicCodeTypeaheadEnabled", enabled),
      [updateSetting],
    ),
  };
};
